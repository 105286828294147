<template>
    <ContentWrapper>
        <breadcrumb/>

        <b-container>

            <div class="mb-5 animated-fast fadeInDown">

                <p>{{ $t('support.view.faq.question_label') }}</p>

                <b-input type="text" class="p-4" :placeholder="$t('support.view.faq.question_placeholder')" v-model="question" @keydown="keydown"/>

                <faq-query class="mt-5" :title="question" :language="$i18n.locale" ref="query"></faq-query>

                <div v-if="question && !$refs.query.loading">
                    <div class="text-right">
                        <p>
                            {{ $t('support.view.faq.no_answer_found') }}
                        </p>

                        <div>
                            <b-btn :to="{name: 'Support:Contact', params: {question: question}}">{{ $t('support.view.faq.button_contact_form') }}</b-btn>
                        </div>

                        <br/>
                        <br/>

                    </div>
                </div>

            </div>

            <tickets :limit="5" class="mb-5"></tickets>

            <div v-for="(category,catIndex) in categories" class="mb-5" v-show="category.questions.length">

                <h4 class="animated-fast fadeInDown"><i :class="category.icon" class="mr-2"></i> {{ category.name }}</h4>

                <accordion-card v-for="(entry, index) in category.questions" @opened="opened(entry)" :key="'index' + index" class="animated-fast fadeInLeft"
                                :style="{'animation-delay': (100*(index+catIndex)) + 'ms'}">
                    <template v-slot:title>
                        {{ entry.question }}
                    </template>

                    <markdown-parser :horizontal-line="false" :highlight="false" :table="false" :source="entry.answer"></markdown-parser>

                </accordion-card>

            </div>

        </b-container>
    </ContentWrapper>
</template>
<script>
    import AccordionCard from "../components/AccordionCard";
    import MarkdownParser from "../../../components/Common/MarkdownParser";
    import FaqQuery from "../components/FaqQuery";
    import Tickets from "../components/Tickets";

    export default {
        components: {
            Tickets,
            FaqQuery,
            MarkdownParser,
            AccordionCard
        },
        data() {
            return {
                selected: undefined,
                categories: [],
                question: ''
            }
        },
        mounted() {
            this.$api.get('support/categories', {language: this.$i18n.locale}).then(response => {
                this.categories = response.data;
            });
        },
        methods: {
            keydown() {
                this.waitingForKeys = true;
                this.$refs.query.triggerLoading();
                clearTimeout(this.keyDownTimeout);
                this.keyDownTimeout = setTimeout(() => {
                    this.loading = true;
                    this.$refs.query.searchAnswer().then(() => {
                        this.waitingForKeys = false;
                        this.loading = false;
                    })
                }, 3500)
            },
            opened(entry) {
                if (!entry.opened) {
                    this.$api.post('support/question/' + entry.id + '/opened');
                    entry.opened = true;
                }
            }
        }
    }
</script>